import ApiService from './ApiService';

class ChatService extends ApiService {
    resource = '/conversation'; 
    resourceLabel = '/label'
    getUnreadConversation(params) {
        return this.get(`${this.resource}/list/count`, params)
    }
    getConversation(params) {
        return this.get(`${this.resource}/list`, params);
    }
    getConvMessage(id, params) {
        return this.get(`${this.resource}/${id}`, params);
    }

    getLabels() {
        return this.get(`${this.resourceLabel}/list`)
    }

    createLabel(params) {
        return this.post(`${this.resourceLabel}/add` , params)
    }

    updateLabelSetting(id, params) {
        return this.put(`${this.resourceLabel}/${id}/update` , params)
    }

    deleteLabelSetting(params) {
        return this.delete(`${this.resourceLabel}/delete` , params)
    }

    updateLabels(id, params) {
        return this.put(`${this.resource}/${id}/set-label`, params)
    }

    uploadImage(image) {
        return this.post(`/file/upload-image`, image)
    }

    postCustomer(id, params) {
        return this.post(`/customer/${id}/update`, params)
    }

    getCustomerHistories(params) {
        return this.get(`/customer/update-histories`, params)
    }

    getListCustomers(params) {
        return this.getArr(`/customer/list`, params)
    }

    getImages(params) {
        return this.get(`/file/list`, params)
    }

    likeComment(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/like`, params)
    }

    unlikeComment(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/unlike`, params)
    }

    likeCommentReply(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/like-reply`, params)
    }

    unlikeCommentReply(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/unlike-reply`, params)
    }

    hideComment(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/hide`, params)
    }

    unhideComment(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/unhide`, params)
    }

    hideCommentReply(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/hide-reply`, params)
    }

    unhideCommentReply(conversationId, commentId, params) {
        return this.get(`${this.resource}/${conversationId}/${commentId}/unhide-reply`, params)
    }
}

const chatService = new ChatService;
export { chatService };
export default ChatService;
